import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero'
import DocumentiUtili from '../components/documenti-utili'
import Layout from "../components/layout"
import { graphql } from "gatsby"


class AreaStudenti extends React.Component {
  
  render() { 
    const breadcrumb = [{"title":"Area enti"}];
    const intro = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "Area enti",
      "img": hero.childImageSharp.fluid,
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Area enti"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero}/>

        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              {!!intro && 
                <div dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {!!intro.attachment &&
                <DocumentiUtili data={intro.attachment}/>
              }
            </div>
          </div>
        </div>

        <div className="box-area-subhome">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                <img src="/images/contents/bear_big.png" alt="" className="box-area-subhome__img" />
                <div className="box-area-subhome__title">Hai bisogno di maggiori informazioni?</div>
                <a href="mailto:direzione.sanitaria.vet@unimi.it" title="Contattaci" className="btn-link">Contattaci</a>
              </div>
            </div>
          </div>
        </div>
		{/*  
        <div className="partner">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Enti convenzionati</h1>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-6 col-md-3  text-center">
                <a href="https://www.ats-milano.it/portale" target="_blank" rel="noopener noreferrer" title="ATS Milano città Metropolitana" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/ATS_milano.png" alt="ATS Milano città Metropolitana" className="partner__img" />
                  </div>
                  <span className="partner__name">ATS Milano città Metropolitana</span>
                </a>
              </div>
              
              <div className="col-6 col-md-3  text-center">
                <a href="https://www.avsoslj.org/" target="_blank" rel="noopener noreferrer" title="A.V.S.S. S.L.J. ODV" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/A.V.S.O.jpg" alt="A.V.S.S. S.L.J. ODV" className="partner__img" />
                  </div>
                  <span className="partner__name">A.V.S.S. S.L.J. ODV</span>
                </a>
              </div>
              <div className="clearfix d-block d-md-none"></div>
              <div className="col-6 col-md-3  text-center">
                <a href="https://www.fattoriacapreecavoli.com/" target="_blank" rel="noopener noreferrer" title="Fattoria Capre e Cavoli " className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/Fattoria_Capre_e_Cavoli.jpg" alt="Fattoria Capre e Cavoli " className="partner__img" />
                  </div>
                  <span className="partner__name">Fattoria Capre e Cavoli </span>
                </a>
              </div>
             
              <div className="col-6 col-md-3  text-center">
                <a href="http://www.adica.org/" target="_blank" rel="noopener noreferrer" title="Adica Lodi" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/adica.JPG" alt="Adica Lodi" className="partner__img" />
                  </div>
                  <span className="partner__name">Adica Lodi</span>
                </a>
              </div>
              <div className="clearfix d-block d-md-none"></div>
              <div className="col-6 col-md-3  text-center">
                <a href="https://www.mondocarota.it/" target="_blank" rel="noopener noreferrer" title="Progetto Mondo Carota" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/MONDO_CAROTA.png" alt="Progetto Mondo Carota" className="partner__img" />
                  </div>
                  <span className="partner__name">Progetto Mondo Carota</span>
                </a>
              </div>
              <div className="col-6 col-md-3  text-center">
                <a href="http://www.enpapavia.it/" target="_blank" rel="noopener noreferrer" title="ENPA PAVIA" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/Enpav_pavia.JPG" alt="ENPA PAVIA" className="partner__img" />
                  </div>
                  <span className="partner__name">ENPA PAVIA</span>
                </a>
              </div>
              <div className="clearfix d-block d-md-none"></div>
              <div className="col-6 col-md-3 text-center">
                <a href="https://www.caniguidalions.it/" target="_blank" rel="noopener noreferrer" title="LIONS" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/Servizio_Cani_Guida_Lions.png" alt="LIONS" className="partner__img" />
                  </div>
                  <span className="partner__name">LIONS</span>
                </a>
              </div>
              <div className="col-6 col-md-3 text-center">
                <a href="javascript:void(0)" title="Progetto Aquila di Lodi" className="partner__link">
                  <div className="partner__img-wrapper">
                    <img src="/images/contents/Progetto_Aquila.webp" alt="Progetto Aquila di Lodi" className="partner__img" />
                  </div>
                  <span className="partner__name">Progetto Aquila di Lodi</span>
                </a>
              </div>
            </div>
          </div>
        </div>      
		*/}
      </Layout>
    )
  }

}

export default AreaStudenti;

export const query = graphql`
query AreaEntiQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "5cEoU6Ow7Cigao20AcCMmG"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  hero: file(relativePath: {eq: "hero-enti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`